import { useState } from 'react'

import { Schemas } from '~/clients/api-client'
import { createPractitionerSchedule, updatePractitionerSchedule } from '~/store/entity.api'
import { PractitionerSchedule, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'
import { day, format } from '~/utils/extendedDayjs'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs/config'
import { getNonEmptyString } from '~/utils/utils'

import { Comment } from '../../shared/Comment'
import { DatePractitionerCell } from '../utils'

async function upsertComment(selectedCell: DatePractitionerCell, practitionerSchedule?: PractitionerSchedule, newComment?: string) {
    const scheduleData: Schemas['PractitionerScheduleCreate'] = practitionerSchedule
        ? {
              practitioner_id: practitionerSchedule.practitioner_id,
              all_day: practitionerSchedule.all_day,
              start_time: format(day(practitionerSchedule.start_time)),
              end_time: format(day(practitionerSchedule.end_time)),
              tzid: practitionerSchedule.tzid,
              location_id: practitionerSchedule.location_id,
              duration: practitionerSchedule.duration,
              comment: newComment,
          }
        : {
              practitioner_id: selectedCell.practitionerId,
              all_day: true,
              start_time: format(selectedCell.date),
              end_time: format(selectedCell.date.add(1, 'day')),
              tzid: HOSPITAL_TIMEZONE,
              comment: newComment,
          }

    if (practitionerSchedule && practitionerSchedule.comment === newComment) return
    return practitionerSchedule ? updatePractitionerSchedule(practitionerSchedule.id, scheduleData) : createPractitionerSchedule(scheduleData)
}

export const PractitionerComment = ($: { cell: DatePractitionerCell }) => {
    const practitionerSchedule = useStore(selectGetPractitionerSchedules).byDateAndPractitionerId($.cell.date, $.cell.practitionerId).at(0)

    const [comment, setComment] = useState(practitionerSchedule?.comment ?? '')
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    async function updateComment() {
        const newComment = getNonEmptyString(comment)

        setIsLoading(true)
        setIsSuccess(false)

        try {
            await upsertComment($.cell, practitionerSchedule, newComment)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            return
        }

        setIsLoading(false)
        setIsSuccess(true)

        setComment(newComment ?? '')
        void trackEvent('SS_BUTTON_REMOVE_SURGEON_COMMENT__CLICK')
    }

    function deleteComment() {
        if (!practitionerSchedule) return
        const prompt = window.confirm('Er du sikker på at du vil fjerne kommentaren?')

        if (!prompt) return

        setComment('')
        void upsertComment($.cell, practitionerSchedule, undefined)

        void trackEvent('SS_BUTTON_REMOVE_SURGEON_COMMENT__CLICK')
    }

    return (
        <Comment
            comment={comment}
            setComment={setComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            isLoading={isLoading}
            isSuccess={isSuccess}
        />
    )
}
