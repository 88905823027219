import { useFetchScheduledItems } from '../hooks/useFetchScheduledItems'
import { useFetchUnscheduledItems } from '../hooks/useFetchUnscheduledItems'
import { Table } from './Table'

export function UnscheduledTable() {
    const { isLoading } = useFetchUnscheduledItems()

    return <Table isLoading={isLoading} />
}

export function ScheduledTable() {
    const { isLoading } = useFetchScheduledItems()

    return <Table isLoading={isLoading} />
}

export function AllTable() {
    const { isLoading: isUnscheduledLoading } = useFetchUnscheduledItems()
    const { isLoading: isScheduledLoading } = useFetchScheduledItems()

    return <Table isLoading={isUnscheduledLoading && isScheduledLoading} />
}
