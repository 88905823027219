import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Show, Spinner } from '~/components'
import { useSurgeryTypeGroups } from '~/hooks/useSurgeryTypeGroups'
import { importSurgeryTypes } from '~/store/dips-entity.api'
import { DipsSurgeryType, selectSurgeryTypeGroup, selectSurgeryTypes, SurgeryTypeGroupId, SurgeryTypeMetadata } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { HospitalCode, SurgeryTypeGroupCode, WeightCode } from '../components/code'
import { List, ListItem } from '../components/list'

type UnimportedHospitalSurgeryTypeListItemProps = {
    code: number
    name: string
}

const UnimportedHospitalSurgeryTypeListItem = ({ code, name }: UnimportedHospitalSurgeryTypeListItemProps) => (
    <ListItem>
        <HospitalCode code={code} /> {name}
    </ListItem>
)

type OrphanHospitalSurgeryTypeListItemProps = {
    surgeryType: DipsSurgeryType
}

const OrphanHospitalSurgeryTypeListItem = ({ surgeryType }: OrphanHospitalSurgeryTypeListItemProps) => (
    <ListItem>
        <HospitalCode code={surgeryType.id} /> {surgeryType.name || '???'}
    </ListItem>
)

type HospitalSurgeryTypeListItemContentProps = {
    surgeryType: DipsSurgeryType
    surgeryTypeGroups: Record<SurgeryTypeGroupId, SurgeryTypeMetadata>
    onClick: () => void
    isExpanded: boolean
}

const HospitalSurgeryTypeListItemContent = ({ surgeryType, surgeryTypeGroups, onClick, isExpanded }: HospitalSurgeryTypeListItemContentProps) => {
    return (
        <div
            className={clsx(
                {
                    'cursor-pointer hover:text-diBlue-600': true,
                },
                'flex flex-row items-center gap-2 border-l-2 pl-2'
            )}
            onClick={onClick}
        >
            {<div className="text-xs">{isExpanded ? '▼' : '▶'}</div>}
            <div>({Object.keys(surgeryTypeGroups).length}) </div>
            <HospitalCode code={Number(surgeryType.id)} />
            <div>{surgeryType.name}</div>
        </div>
    )
}

type HospitalSurgeryTypeListItemProps = {
    surgeryType: DipsSurgeryType
    inSurgeryTypeGroups: Record<SurgeryTypeGroupId, SurgeryTypeMetadata>
}

const HospitalSurgeryTypeListItem = ({ surgeryType, inSurgeryTypeGroups }: HospitalSurgeryTypeListItemProps) => {
    const getSurgeryTypeGroups = useStore(selectSurgeryTypeGroup)

    const [isExpanded, setIsExpanded] = useState(false)

    const handleContentClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    return (
        <ListItem>
            <HospitalSurgeryTypeListItemContent
                surgeryType={surgeryType}
                surgeryTypeGroups={inSurgeryTypeGroups}
                onClick={handleContentClick}
                isExpanded={isExpanded}
            />
            <Show condition={isExpanded}>
                <List>
                    {Object.keys(inSurgeryTypeGroups).map(id => {
                        const surgeryTypeGroupId = Number(id)
                        const surgeryTypeGroup = getSurgeryTypeGroups.byId(surgeryTypeGroupId)
                        const metadata = inSurgeryTypeGroups[Number(surgeryTypeGroupId)]
                        return (
                            <div key={surgeryTypeGroupId}>
                                <SurgeryTypeGroupCode code={surgeryTypeGroup?.code || ''} /> <WeightCode code={metadata?.weight} />
                                {surgeryTypeGroup?.display_name}
                            </div>
                        )
                    })}
                </List>
            </Show>
        </ListItem>
    )
}

const HospitalSurgeryTypes = () => {
    const { isError: isDipsError, isLoading: isDipsLoading } = useImportEntities(() => [importSurgeryTypes()], [])
    const { isError: isSurgeryTypeGroupsError, isLoading: isSurgeryTypeGroupsLoading } = useSurgeryTypeGroups()
    const isLoading = isDipsLoading || isSurgeryTypeGroupsLoading
    const isError = isDipsError || isSurgeryTypeGroupsError

    const getSurgeryTypes = useStore(selectSurgeryTypes)
    const unimportedHospitalSurgeryTypes = getSurgeryTypes.byGrouping('unimported')
    const deprecatedHospitalSurgeryTypes = getSurgeryTypes.byDeprecatedHospitalSurgeryType()
    const orphanHospitalSurgeryTypes = getSurgeryTypes.byGrouping('orphan')
    const hospitalSurgeryTypesInGroups = getSurgeryTypes.byGrouping('in-groups')

    return (
        <div className="flex flex-col gap-2">
            {isLoading && <Spinner />}
            {isError && <div className="text-red-500">Error loading surgery type groups</div>}
            {
                <div key="unimported-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({unimportedHospitalSurgeryTypes.length}) Unimported surgery types</div>
                    <List>
                        {unimportedHospitalSurgeryTypes.map(surgeryType => {
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <UnimportedHospitalSurgeryTypeListItem code={Number(surgeryType.id)} name={surgeryType.name || '???'} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="deprecated-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({deprecatedHospitalSurgeryTypes.length}) Deprecated surgery types</div>
                    <List>
                        {deprecatedHospitalSurgeryTypes.map(surgeryType => {
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <UnimportedHospitalSurgeryTypeListItem
                                        code={Number(surgeryType.hospital_surgery_type_id)}
                                        name={surgeryType.hospital_surgery_type_name}
                                    />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="orphans-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({orphanHospitalSurgeryTypes.length}) Orphan surgery types</div>
                    <List>
                        {orphanHospitalSurgeryTypes.map(surgeryType => {
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <OrphanHospitalSurgeryTypeListItem surgeryType={surgeryType} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="resolved-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({hospitalSurgeryTypesInGroups.length}) Surgery types in group(s)</div>
                    <List>
                        {hospitalSurgeryTypesInGroups.map(surgeryType => {
                            const surgeryTypeGroups = getSurgeryTypes.byHospitalSurgeryTypeId(Number(surgeryType.id))
                            if (!surgeryTypeGroups) {
                                throw new Error('Surgery type groups not found')
                            }
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <HospitalSurgeryTypeListItem surgeryType={surgeryType} inSurgeryTypeGroups={surgeryTypeGroups} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
        </div>
    )
}

export default HospitalSurgeryTypes
