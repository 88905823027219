import { ReactNode } from 'react'

import { PagePaths } from '~/app/routes'
import { ScheduledSurgery, UnScheduledSurgery } from '~/store/selectors'
import { DepartmentKey, getDayOvernight, isDayOvernightKey } from '~/store/slices/filterSlice'
import { formatPersonalIdWithLastName } from '~/utils/dips/patient'
import { getAssistants, getSurgeons } from '~/utils/dips/surgeryResources'
import { day, diff, format, formatDuration, getToday } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'
import { getNorwegianText } from '~/utils/localization'

import { type TransformedWaitingListItem } from '../hooks/useWaitingListData'
import { shouldShowASAColumn, waitingTimeTooltip } from './utils'

export type WaitingListItem = UnScheduledSurgery | ScheduledSurgery

function isUnScheduledSurgery(item: WaitingListItem): item is UnScheduledSurgery {
    return 'bookingId' in item
}
function getFormattedBookingId(item: WaitingListItem) {
    if (isUnScheduledSurgery(item) && item.bookingId) return item.bookingId
    if ('id' in item && item.id) return item.id
    return ''
}

type Column = {
    // for the header
    label: string | undefined | ReactNode
    // format data to text, used in renders and in searching (case-insensitive)
    format: (item: WaitingListItem) => string | string[]
    // for sorting
    getComparable: (item: WaitingListItem) => string | number | null
    // whether its column is displayed
    showColumn: boolean | ((departmentId: DepartmentKey, activeView: PagePaths['WAITING_LIST']) => boolean)
}

export const columns = {
    Icon: {
        showColumn: true,
        label: undefined,
        format: item => getDayOvernight(item.contact?.levelOfCareNpr?.nprCodeName)?.label ?? '',
        getComparable: item => (!isDayOvernightKey(item.contact?.levelOfCareNpr?.nprCodeName) ? 1 : 0),
    },
    WaitingTime: {
        showColumn: true,
        label: (
            <div data-tooltip={waitingTimeTooltip} className="inline">
                Dager ventet
            </div>
        ),
        format: item => String(diff(getToday(), item.surgeryOrderDetails?.orderedDate, 'day')),
        getComparable: item => diff(getToday(), item.surgeryOrderDetails?.orderedDate, 'seconds'),
    },
    TentativeDate: {
        showColumn: (_, statusFilter) => statusFilter === '/waiting-list/all' || statusFilter === '/waiting-list/unscheduled',
        label: 'Tentativ operasjon',
        format: item =>
            isNullish(item.contact?.checkInTime) || ['NotTentative', undefined].includes(item.contact?.tentative)
                ? ''
                : format(day(item.contact!.checkInTime), item.contact!.tentative === 'TentativeMonth' ? 'MMMM YYYY' : 'DD MMM YYYY'),
        getComparable: item =>
            isNullish(item.contact?.checkInTime)
                ? '-1'
                : String((['NotTentative', 'TentativeDate', 'TentativeMonth', undefined] as const).indexOf(item.contact!.tentative)) +
                  day(item.contact!.checkInTime).unix(),
    },
    OperationDate: {
        showColumn: (_, statusFilter) => statusFilter === '/waiting-list/all' || statusFilter === '/waiting-list/scheduled',
        label: 'O-dato',
        format: item => (item.contact?.tentative === 'NotTentative' && item.contact?.checkInTime ? format(day(item.contact!.checkInTime), 'DD.MM.YYYY') : ''),
        getComparable: item =>
            isNullish(item.contact?.checkInTime)
                ? Infinity
                : String((['NotTentative', 'TentativeDate', 'TentativeMonth', undefined] as const).indexOf(item.contact!.tentative)) +
                  day(item.contact!.checkInTime).unix(),
    },
    Patient: {
        showColumn: true,
        label: 'Pasient',
        format: item => `${formatPersonalIdWithLastName(item.patient)}`,
        getComparable: item => item.patient?.lastName ?? null,
    },
    Comment: {
        showColumn: true,
        label: (
            <div data-test="comment-header" className="inline">
                Oppmøtekommentar
            </div>
        ),
        format: item => item.diWaitingListItem?.comment ?? item.contact?.coordinationComment ?? '',
        getComparable: item => item.diWaitingListItem?.comment ?? item.contact?.coordinationComment ?? null,
    },

    DIPSComment: {
        label: 'Oppmøtekommentar fra DIPS',
        format: item => item.contact?.coordinationComment ?? '',
        getComparable: item => item.contact?.coordinationComment ?? null,
        showColumn: false,
    },
    ASA: {
        label: 'ASA',
        format: item => item.surgeryOrderDetails?.asa ?? '',
        getComparable: item => item.surgeryOrderDetails?.asa ?? null,
        showColumn: shouldShowASAColumn,
    },
    ShortNotice: {
        showColumn: (_, view) => view !== '/waiting-list/scheduled', // see PF-1522
        label: <div data-test="Kort varsel header">Kort varsel</div>,
        format: item => (item.contact?.isShortNotice === true ? 'Kort varsel' : ''),
        getComparable: item => (item.contact?.isShortNotice === true ? 0 : 1),
    },
    Surgeons: {
        showColumn: true,
        label: 'Kirurg',
        format: item =>
            getSurgeons(item.surgeryResources)
                .map(({ short_name }) => short_name)
                .filter(Boolean)
                .sort(),
        getComparable: item =>
            getSurgeons(item.surgeryResources)
                .map(({ short_name }) => short_name)
                .filter(Boolean)
                .sort()
                .join(' '),
    },
    SurgeryType: {
        showColumn: true,
        label: 'Operasjonstype',
        format: item => item.surgeryType?.name ?? '',
        getComparable: item => item.surgeryType?.name ?? null,
    },
    KnifeTime: {
        showColumn: true,
        label: 'Knivtid',
        format: item => (item?.plannedProcedureDuration && formatDuration(item.plannedProcedureDuration)) || '',
        getComparable: item => item?.plannedProcedureDuration ?? null,
    },
    /* 'Language': { label: 'Tolk', get: item => ? }, */
    /* 'City': { label: 'Poststed', get: item => ? }, */

    BookingId: {
        label: 'Id',
        format: getFormattedBookingId,
        getComparable: getFormattedBookingId,
        showColumn: false,
    },
    BMI: {
        showColumn: false,
        label: 'BMI',
        format: item => item.surgeryOrderDetails?.bmi ?? '',
        getComparable: item => item.surgeryOrderDetails?.bmi ?? '',
    },
    ContactReason: {
        showColumn: false,
        label: 'Kontaktårsak',
        format: item => item.contact?.note ?? '',
        getComparable: item => item.contact?.note ?? null,
    },
    FirstAssistants: {
        showColumn: false,
        label: 'Assisterende kirurg',
        format: item =>
            getAssistants(item.surgeryResources)
                .map(({ short_name }) => short_name)
                .filter(Boolean)
                .sort(),
        getComparable: item =>
            getAssistants(item.surgeryResources)
                .map(({ short_name }) => short_name ?? '')
                .sort()
                .join(' '),
    },
    Day: {
        showColumn: false,
        label: 'Dag/Døgn',
        format: item => getDayOvernight(item.contact?.levelOfCareNpr?.nprCodeName)?.label ?? '',
        getComparable: item => item.contact?.levelOfCareNpr?.nprCodeName ?? null,
    },
    Age: {
        showColumn: false,
        label: 'Alder',
        format: item => `${(item.patient?.birthDate && String(diff(getToday(), item.patient.birthDate, 'year'))) || '?'} ${getNorwegianText('year')}`,
        getComparable: item => item.patient?.birthDate ?? null,
    },
    BirthDate: {
        // makes the patient searchable by birth date
        showColumn: false,
        label: 'fødselsdato',
        format: item => (item.patient?.birthDate && `Fødselsdato:\n${format(day(item.patient.birthDate), 'DD.MM.YYYY')}`) || '',
        getComparable: item => item.patient?.birthDate ?? null,
    },
    BookedStatus: {
        showColumn: false,
        label: undefined,
        format: item => (isUnScheduledSurgery(item) ? (item.contact?.tentative === 'NotTentative' ? 'NotTentative' : 'Waiting') : 'Scheduled'),
        getComparable: item => (item.contact?.tentative === 'NotTentative' ? 1 : 0),
    },
} satisfies Record<string, Column>

export type WaitingListColumn = keyof typeof columns
export type FormattedWaitingListItem = { [K in keyof TransformedWaitingListItem]: TransformedWaitingListItem[K]['formatted'] }
