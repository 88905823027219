import { importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { importWaitingListItems } from '~/store/entity.api'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

export function useFetchUnscheduledItems() {
    const hasUnScheduledItems = useStore(state => Boolean(state.dips.entities.unScheduledSurgeries.allIds.length))

    const { isLoading } = useImportEntities(() => [importUnScheduledSurgeries(), importWaitingListItems({})], [])

    return {
        isLoading: isLoading && !hasUnScheduledItems,
    }
}
