import React from 'react'

import { ChildOutlined } from '~/icons'
import { AgeGroupCode } from '~/store/diApi'
import { selectGetAgeGroups } from '~/store/selectors'
import { useStore } from '~/store/store'

type Props = {
    ageGroupCode?: AgeGroupCode | null
}

export const AgeGroup = ({ ageGroupCode }: Props) => {
    const getAgeGroup = useStore(selectGetAgeGroups)
    if (ageGroupCode === 'child') {
        const ageGroup = getAgeGroup.byCode(ageGroupCode)
        return (
            <div className="py-0\\.5 flex items-center gap-1 rounded border px-2">
                <ChildOutlined />
                {ageGroup?.display_name || 'Barn'}
            </div>
        )
    }
    return null
}
