import {
    importAgeGroups,
    importHospitalSurgeryTypeGroupAssociations,
    importHospitalSurgeryTypes,
    importSpecialities,
    importSurgeryTypeGroupAgeRestrictions,
    importSurgeryTypeGroupHierarchies,
    importSurgeryTypeGroups,
    importSurgeryTypeGroupSpecialties,
} from '~/store/entity.api'
import { useImportEntities } from '~/store/useImportEntities'

export function useSurgeryTypeGroups() {
    return useImportEntities(
        () => [
            importSpecialities({}),
            importAgeGroups({}),
            importSurgeryTypeGroupAgeRestrictions({}),
            importSurgeryTypeGroupHierarchies({}),
            importSurgeryTypeGroupSpecialties({}),
            importSurgeryTypeGroups({}),
            importHospitalSurgeryTypes({}),
            importHospitalSurgeryTypeGroupAssociations({}),
        ],
        []
    )
}
