import { useMemo } from 'react'

import { comparePractitionerOrder, MinimalPractitioner, PractitionerResourceNames, PractitionerResourceType } from '~/utils/dips/surgeryResources'

import { SurgeonCodeTag } from './SurgeonCodeTag'

const prefix = {
    [PractitionerResourceNames.Assistant]: 'A',
    [PractitionerResourceNames.FirstAssistant]: 'A',
    [PractitionerResourceNames.Surgeon]: 'H',
} satisfies Record<PractitionerResourceType, string>

export const PractitionerTags = ({ practitioners }: { practitioners: MinimalPractitioner[]; style: 'blue' | 'transparent' }) => {
    const practs = useMemo(
        () =>
            practitioners
                .filter(p => !!p.short_name)
                .sort((a, b) => {
                    const comparison = comparePractitionerOrder(a.practitionerType, b.practitionerType)
                    return comparison !== 0 ? comparison : a.short_name.localeCompare(b.short_name)
                })
                .map(p => ({ ...p, short_name: p.short_name.toLocaleUpperCase() })),
        [practitioners]
    )

    if (practs.length === 0) return null

    return (
        <div className="flex flex-wrap gap-2">
            {practs.map((p, index) => (
                <SurgeonCodeTag surgeonCode={p.short_name} prefix={p.practitionerType && prefix[p.practitionerType]} key={`${p.practitionerType}-${index}`} />
            ))}
        </div>
    )
}
