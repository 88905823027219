import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { AgeGroup, Button, Spinner, Tag } from '~/components'
import { ExclamationCircleOutlined, PhoneIconOutlined } from '~/icons'
import { SurgeryForPresentation } from '~/utils/dips/surgeryResources'

import { BookingInfo } from './BookingInfo'

interface Props extends SurgeryForPresentation {
    isLoading: boolean
    defaultCheckInTime: string
    defaultKnifeTime: string
    defaultError?: string
    onSubmit: (checkInTime: string, startTime: string) => void
    onClose: () => void
}

const labelInputStyles = 'grid max-w-[332px] grid-cols-[1fr_132px] items-center justify-between text-gray-500 gap-4'

const inputStyles = 'rounded-lg border p-1 font-normal outline-none'

export const BookingForm = ({
    operation,
    patient,
    practitioners,
    location,
    date,
    comment,
    defaultCheckInTime,
    defaultKnifeTime,
    isLoading,
    asa,
    dayOvernight,
    patientAgeGroup,
    isShortNotice,
    defaultError,
    onSubmit,
    onClose,
}: Props) => {
    const [checkInTime, setCheckInTime] = useState<string>(defaultCheckInTime)
    const [startTime, setStartTime] = useState<string>()
    const [hasInitialFormData, setHasInitialFormData] = useState(false)
    const [error, setError] = useState<string | undefined>(defaultError)

    useEffect(() => {
        const initialFormData = defaultCheckInTime || defaultKnifeTime

        if (!hasInitialFormData && initialFormData) {
            setCheckInTime(defaultCheckInTime)
            setHasInitialFormData(true)
        }
    }, [hasInitialFormData, defaultCheckInTime, defaultKnifeTime])

    const handleSubmit = () => {
        if (!checkInTime || !startTime) {
            setError('Vennligst fyll ut alle feltene')
            return
        }
        onSubmit(checkInTime, startTime)
    }

    return (
        <>
            <p className="text-base font-semibold">Om pasienten og inngrepet</p>
            <dl className="relative mb-5 grid w-[585px] justify-between gap-6">
                <BookingInfo label="Operasjon">{operation}</BookingInfo>
                <BookingInfo label="Merknad">
                    <div className="flex gap-2">
                        {asa && (
                            <Tag color="ASA" textSize="sm">
                                {asa}
                            </Tag>
                        )}
                        {dayOvernight && (
                            <div className="flex items-center gap-1 rounded border px-2 py-0.5">
                                {dayOvernight.icon}
                                <p>{dayOvernight.label}</p>
                            </div>
                        )}
                        {isShortNotice && (
                            <div className="flex items-center gap-1 rounded border px-2 py-0.5">
                                <PhoneIconOutlined />
                                <p>Kort Varsel</p>
                            </div>
                        )}
                        {patientAgeGroup && patientAgeGroup.age_group_code === 'child' && <AgeGroup ageGroupCode={patientAgeGroup?.age_group_code} />}
                    </div>
                </BookingInfo>
                <BookingInfo label="Pasient">{patient}</BookingInfo>
                <BookingInfo label="Kirurg">
                    {practitioners
                        .filter(prac => prac.name !== undefined)
                        .map((prac, i) => (
                            <dd key={prac.name}>{`${i > 0 ? ',' : ''}${prac.short_name}`}</dd>
                        ))}
                </BookingInfo>
                <BookingInfo label="Stue">{location}</BookingInfo>
                <BookingInfo label="Dato">
                    <dd className="capitalize">{date}</dd>
                </BookingInfo>
                <BookingInfo label="Kommentar">{comment}</BookingInfo>
                <div className="grid grid-cols-[120px_1fr] text-base">
                    <p className="text-gray-500">Tider</p>
                    <div className="flex flex-col gap-2">
                        <label className={labelInputStyles}>
                            Oppmøtetid (klokkeslett)
                            <input
                                type="time"
                                id="check-in-time"
                                name="check-in-time"
                                className={inputStyles}
                                value={checkInTime}
                                onChange={e => setCheckInTime(e.target.value)}
                            />
                        </label>
                        <label className={labelInputStyles}>
                            Stue inn (klokkeslett)
                            <input
                                className={inputStyles}
                                type="time"
                                id="stue-inn"
                                data-test="stue-inn"
                                name="stue-inn"
                                onChange={e => {
                                    setError(undefined)
                                    setStartTime(e.target.value)
                                }}
                            />
                        </label>
                        <label className={labelInputStyles}>
                            Knivtid
                            <div>&nbsp;{defaultKnifeTime}</div>
                        </label>
                    </div>
                </div>
                {error && (
                    <motion.p
                        initial={{ opacity: 0, y: 16 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ type: 'linear' }}
                        className="absolute bottom-[-36px] flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white"
                    >
                        <ExclamationCircleOutlined height={20} />
                        {error}
                    </motion.p>
                )}
            </dl>

            <div className="mt-2 flex justify-end gap-2">
                <Button color="delete" onClick={onClose}>
                    Avbryt
                </Button>
                <Button onClick={handleSubmit} disabled={isLoading || !!error} data-test={isLoading ? '' : 'confirm-button'}>
                    <span className="flex gap-2">{isLoading && <Spinner />}Planlegg operasjon</span>
                </Button>
            </div>
        </>
    )
}
