import * as Sentry from '@sentry/browser'
import isArray from 'lodash/isArray'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { ScheduledSurgery, UnScheduledSurgery } from '~/store/selectors'
import {
    isDayOvernightKey,
    selectASAValues,
    selectDayOvernightValues,
    selectOperationTypeValues,
    selectPractitionerValues,
    selectShortNoticeValues,
} from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { getSurgeons } from '~/utils/dips/surgeryResources'
import { needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { selectWaitingListItems } from '../selectors/waitingListItems'
import { columns, FormattedWaitingListItem, WaitingListColumn, WaitingListItem } from '../shared/columns'

function transform(item: WaitingListItem) {
    const result: Record<string, unknown> = {}
    for (const [column, definitions] of Object.entries(columns)) {
        result[column] = {
            comparable: definitions.getComparable(item),
            formatted: definitions.format(item),
        }
    }

    return result as {
        [K in keyof typeof columns]: {
            formatted: ReturnType<(typeof columns)[K]['format']>
            comparable: ReturnType<(typeof columns)[K]['getComparable']>
        }
    }
}

export type TransformedWaitingListItem = ReturnType<typeof transform>

function containsNeedle(needle: string) {
    const filterTokens = needleToTokens(needle)

    return (item: TransformedWaitingListItem) => {
        return filterTokens.every(token =>
            Object.values(item).some(value =>
                isArray(value.formatted)
                    ? value.formatted.some(formatted => formatted.toLocaleLowerCase().indexOf(token) !== -1)
                    : value.formatted.toLocaleLowerCase().indexOf(token) !== -1
            )
        )
    }
}

function getFormattedOnly(item: ReturnType<typeof transform>) {
    const result: Record<string, unknown> = {}
    for (const key of Object.keys(item)) {
        result[key] = item[key as WaitingListColumn].formatted
    }
    return result as FormattedWaitingListItem
}

function dayOvernight(dayOvernightValues: string[]) {
    return (item: UnScheduledSurgery | ScheduledSurgery) => {
        if (dayOvernightValues.length === 0) return true

        const nprCodeName = item.contact?.levelOfCareNpr?.nprCodeName

        // If valid value, then filter, otherwise include to not hide invalid values
        return isDayOvernightKey(nprCodeName) ? dayOvernightValues.includes(nprCodeName) : true
    }
}

export const useWaitingListData = (needle: string) => {
    const columnSortedOn = useStore(state => state.waitingList.columnSortedOn)
    const sortOrder = useStore(state => state.waitingList.sortOrder)
    const surgeries = useStore(selectWaitingListItems)

    const shortNoticeValue = useStore(selectShortNoticeValues)?.at(0)
    const asaValues = useStore(selectASAValues)
    const practitionerValues = useStore(selectPractitionerValues)
    const operationTypesValues = useStore(selectOperationTypeValues)
    const dayOvernightValues = useStore(selectDayOvernightValues)

    const filteredItems = useMemo(
        () =>
            surgeries
                .filter(item => shortNoticeValue === undefined || item.contact?.isShortNotice)
                .filter(item => asaValues.length === 0 || asaValues.includes(item.surgeryOrderDetails?.asa ?? ''))
                .filter(
                    item =>
                        practitionerValues.length === 0 || getSurgeons(item.surgeryResources).some(surgeon => practitionerValues.includes(surgeon.short_name))
                )
                .filter(item => operationTypesValues.length === 0 || operationTypesValues.includes(item.surgeryType?.name ?? ''))
                .filter(dayOvernight(dayOvernightValues)),
        [surgeries, shortNoticeValue, asaValues, practitionerValues, operationTypesValues, dayOvernightValues]
    )

    const result = useMemo(
        () =>
            Sentry.startSpan({ name: 'WL:transform+containsNeedle' }, () =>
                orderBy(
                    filteredItems // prevent autoformatting comment
                        .map(transform)
                        .filter(containsNeedle(needle)),
                    item => item[columnSortedOn].comparable,
                    sortOrder
                ).map(getFormattedOnly)
            ),
        [filteredItems, needle, sortOrder, columnSortedOn]
    )

    return result
}
