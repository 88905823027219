import { importFullScheduledSurgeries } from '~/store/dips-entity.api'
import {
    importBlockLocks,
    importBlockSchedules,
    importDepartmentLocationAssignments,
    importDepartmentPractitionerAssignments,
    importLocations,
    importLocationSchedules,
    importPractitioners,
    importPractitionerSchedules,
    importRuleDefinitions,
} from '~/store/entity.api'
import { useImportEntities } from '~/store/useImportEntities'
import { getEntityTimeArgs } from '~/utils/utils'

export function useImportOperationalPlannerData(startTime: string, endTime: string) {
    const args = getEntityTimeArgs(startTime, endTime)
    const { isLoading } = useImportEntities(
        () => [
            importRuleDefinitions({}),
            importBlockSchedules({ ...args }),
            importBlockLocks({ ...args }),
            importPractitioners({}),
            importPractitionerSchedules({ ...args, exclude_practitioner: true }),
            importDepartmentPractitionerAssignments({}),
            importLocations({}),
            importLocationSchedules({ ...args, exclude_location: true }),
            importDepartmentLocationAssignments({}),
        ],
        [args]
    )

    const { isLoading: isLoadingDips } = useImportEntities(() => [importFullScheduledSurgeries(startTime, endTime)], [startTime, endTime], {
        refetchInterval: 1000 * 30,
    })

    return { isLoading: isLoading || isLoadingDips }
}
